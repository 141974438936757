import React, { useEffect } from "react";

function MenuModal({ isOpen, onClose, children, className }) {
    // Estää etusivun scrollaamisen modaalin "läpi"
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        }
        return () => {
            document.body.style.overflow = "visible";
        };
    }, [isOpen]);

    return (
        isOpen && (
            <div className={`menu-modal ${className}`}>
                <button className="close-button" onClick={onClose}>
                    X
                </button>
                {children}
            </div>
        )
    );
}

export default MenuModal;
