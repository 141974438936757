import React, { useState } from "react";

const LazyImage = ({ highResSrc, lowResSrc, alt, className, onClick }) => {
    const [loaded, setLoaded] = useState(false);

    return (
        <>
            <img
                className={className}
                src={lowResSrc}
                alt={alt}
                style={{
                    display: loaded ? "none" : "block",
                }}
                onClick={onClick}
            />
            <img
                className={className}
                src={highResSrc}
                alt={alt}
                onLoad={() => setLoaded(true)}
                style={{
                    display: loaded ? "block" : "none",
                }}
                onClick={onClick}
            />
        </>
    );
};

export default LazyImage;