import React, { useState } from "react";

function HamburgerIcon({ onClick }) {
    return (
        <button className="hamburger-icon" onClick={onClick}>
            <div></div>
            <div></div>
            <div></div>
        </button>
    );
}

export default HamburgerIcon;
