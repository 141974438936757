import React from "react";

const YouTubeModal = ({
    videoId,
    onClose,
    onNext,
    onPrev,
    totalVideos,
    currentIndex,
}) => {
    const renderIndicators = () => {
        return Array.from({ length: totalVideos }, (_, index) => (
            <span
                key={index}
                className={`carousel-indicator ${
                    index === currentIndex ? "active" : ""
                }`}
            />
        ));
    };

    return (
        <div className="modal-background">
            <button className="close-button" onClick={onClose}>
                X
            </button>
            <div className="youtube-video-paskuli">
                <div className="youtube-video-container">
                    <iframe
                        id="ytplayer"
                        type="text/html"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className="youtube-video"
                        src={`https://www.youtube.com/embed/${videoId}`}
                    ></iframe>
                </div>
                <div className="controls">
                    <button
                        className="navigation-button prev-button"
                        onClick={onPrev}
                    >
                        
                    </button>
                    <div className="carousel-indicators">
                        {renderIndicators()}
                    </div>
                    <button
                        className="navigation-button next-button"
                        onClick={onNext}
                    >
                        
                    </button>
                </div>
            </div>
        </div>
    );
};

export default YouTubeModal;
