import React from "react";
import "../App.css";
import LazyImage from "./LazyImage";

import highResLogo from "../images/logo/logo.jpg";
import lowResLogo from "../images/logo/image.jpg";

function MeriBjorn() {
    return (
        <div className="merinLogo">
            <div className="merinLogoImgContainer">
                <LazyImage
                    className="merinLogoImg"
                    highResSrc={highResLogo}
                    lowResSrc={lowResLogo}
                    alt="Meri Björn Logo"
                />
            </div>
            <div className="merinLogoText">
                <a className="logoTextNimi">
                    meri björn
                </a>
                <a href="mailto:meri@meribjorn.com" className="logoTextEmail">
                    meri@meribjorn.com
                </a>
            </div>
        </div>
    );
}

export default MeriBjorn;
