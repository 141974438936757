import React, { useState, useEffect } from "react";
import "./App.css";
import Modal from "./components/modal";
import LazyImage from "./components/LazyImage";
import HamburgerIcon from "./components/hamburger-icon";
import MenuModal from "./components/menuModal";
import YouTubeModal from "./components/youtubeModal";
import AboutMe from "./components/aboutMe";

import image1 from "./images/1/001.jpg";
import image2 from "./images/2/001.jpg";
import image3 from "./images/3/001.jpg";
import image4 from "./images/4/001.jpg";
import image5 from "./images/5/001.jpg";
import image6 from "./images/6/001.jpg";
import image7 from "./images/7/001.jpg";
import image8 from "./images/8/001.jpg";
import image9 from "./images/9/001.jpg";
import image10 from "./images/10/001.jpg";
import image11 from "./images/11/001.jpg"; //video
import MeriBjorn from "./components/meriBjorn";

import blur1 from "./images/1/image.jpg";
import blur2 from "./images/2/image.jpg";
import blur3 from "./images/3/image.jpg";
import blur4 from "./images/4/image.jpg";
import blur5 from "./images/5/image.jpg";
import blur6 from "./images/6/image.jpg";
import blur7 from "./images/7/image.jpg";
import blur8 from "./images/8/image.jpg";
import blur9 from "./images/9/image.jpg";
import blur10 from "./images/10/image.jpg";
import blur11 from "./images/11/image.jpg"; //video

const images = {
    1: image1,
    2: image2,
    3: image3,
    4: image4,
    5: image5,
    6: image6,
    7: image7,
    8: image8,
    9: image9,
    10: image10,
    11: image11,
};

function App() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalSrc, setModalSrc] = useState(null);
  const [modalError, setModalError] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [showYouTubeModal, setShowYouTubeModal] = useState(false);
  const [youtubeVideoId, setYoutubeVideoId] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [galleryData, setGalleryData] = useState({});
  const [videoIds, setVideoIds] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [showAboutMe, setShowAboutMe] = useState(false);
    const [AboutMeText, setAboutMeText] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = {};
                for (let i = 1; i <= 10; i++) {
                    const response = await fetch(
                        `https://meribjorn.com/server/descriptions/${i}`
                    );
                    data[i] = await response.json();
                }
                // Update the state with the fetched data
                setGalleryData(data);
            } catch (error) {
                console.error("Error fetching JSON data:", error);
            }
        };

        fetchData();

    fetch("https://meribjorn.com/server/descriptions/videos")
      .then((response) => response.json())
      .then((data) => setVideoIds(data.videos))
      .catch((error) => console.error("Error fetching video IDs:", error));
    }, []);

    useEffect(() => {
      
    })

      const openYouTubeModal = (videoId) => {
        setShowYouTubeModal(true);
        setYoutubeVideoId(videoId);
      };

    const closeYouTubeModal = () => {
        setShowYouTubeModal(false);
        setYoutubeVideoId("");
    };

  const goToNextVideo = () => {
    const nextIndex = (currentVideoIndex + 1) % videoIds.length;
    setCurrentVideoIndex(nextIndex);
    setYoutubeVideoId(videoIds[nextIndex]);
  };

  const goToPrevVideo = () => {
    const prevIndex =
      (currentVideoIndex - 1 + videoIds.length) % videoIds.length;
    setCurrentVideoIndex(prevIndex);
    setYoutubeVideoId(videoIds[prevIndex]);
  };
    const openAboutMe = (text) => {
        setShowAboutMe(true);
        setAboutMeText(text);
    };

    const closeAboutMe = () => {
        setShowAboutMe(false);
        setAboutMeText("");
    };

  const openModal = (id, title) => {
    setModalTitle(title);
    setIsModalVisible(true);
    setModalError(null);
    fetch(`https://meribjorn.com/server/list-images/${id}`)
      .then((response) => response.json())
      .then((data) => {
        // data returned is an array of image URLs
        setModalSrc(data);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
        setModalError("Something went wrong... Try again! 😯");
      });
    document.body.classList.add("no-scroll");
  };

    const closeModal = () => {
        setModalSrc(null);
        setIsModalVisible(false);
        document.body.classList.remove("no-scroll");
    };

    function getImageSrcById(id) {
        return images[id];
    }

    const toggleMenu = () => {
        if (!isMenuOpen) {
            setIsClosing(false);
        }
        setIsMenuOpen(!isMenuOpen);
    };
    const closeMenu = () => {
        setIsClosing(true);
        setTimeout(() => setIsMenuOpen(false), 500);
    };

    return (
        <div className="App">
            <div className="App-container">
                <div className="header">
                    <div className="logo">
                        <MeriBjorn />
                    </div>
                    <div className="hamburger">
                        <HamburgerIcon onClick={toggleMenu} />
                    </div>
                    <div className="spacer"></div>
                    <div className="container">
                        <div className="grid--4 topSide">
                            <LazyImage
                                className="pic1"
                                highResSrc={getImageSrcById(1)}
                                lowResSrc={blur1}
                                alt="picture 1"
                                onClick={() =>
                                    openModal(
                                        1,
                                        galleryData[1]?.title || "1 - Work"
                                    )
                                }
                            />
                            <LazyImage
                                className="picture landscape pic2"
                                highResSrc={getImageSrcById(2)}
                                lowResSrc={blur2}
                                alt="picture 2"
                                onClick={() =>
                                    openModal(
                                        2,
                                        galleryData[2]?.title || "2 - Jen Littlejohn"
                                    )
                                }
                            />
                            <LazyImage
                                className="pic3"
                                highResSrc={getImageSrcById(3)}
                                lowResSrc={blur3}
                                alt="picture 3"
                                onClick={() =>
                                    openModal(
                                        3,
                                        galleryData[3]?.title || "3 - Fashion"
                                    )
                                }
                            />
                            <LazyImage
                                className="picture pic4"
                                highResSrc={getImageSrcById(4)}
                                lowResSrc={blur4}
                                alt="picture 4"
                                onClick={() =>
                                    openModal(
                                        4,
                                        galleryData[4]?.title || "4 - Portrais of Friends & (ex)Lovers"
                                    )
                                }
                            />
                        </div>
                        <div className="grid--3 mid">
                            <LazyImage
                                className="pic5"
                                highResSrc={getImageSrcById(5)}
                                lowResSrc={blur5}
                                alt="picture 5"
                                onClick={() =>
                                    openModal(
                                        5,
                                        galleryData[5]?.title || "5 - Barbie"
                                    )
                                }
                            />
                            <LazyImage
                                className="pic6"
                                highResSrc={getImageSrcById(6)}
                                lowResSrc={blur6}
                                alt="picture 6"
                                onClick={() =>
                                    openModal(
                                        6,
                                        galleryData[6]?.title || "6 - Rodeway Inn"
                                    )
                                }
                            />
                            <LazyImage
                                className="pic9"
                                highResSrc={getImageSrcById(7)}
                                lowResSrc={blur7}
                                alt="picture 7"
                                onClick={() =>
                                    openModal(
                                        7,
                                        galleryData[7]?.title || "7 - Milja"
                                    )
                                }
                            />
                            {/*
                            <div className="comissionedText">
                                <p className="comissionedTextStyle">
                                    COMISSIONED AND <br></br> CLIENTLIST
                                </p>
                            </div>
                            */}
                            <LazyImage
                                className="pic7"
                                highResSrc={getImageSrcById(8)}
                                lowResSrc={blur8}
                                alt="picture 8"
                                onClick={() =>
                                    openModal(
                                        8,
                                        galleryData[8]?.title || "8 - Jung Und Schön"
                                    )
                                }
                            />
                        </div>
                        <div className="grid--4 botSide">
                            <LazyImage
                                className="pic8"
                                highResSrc={getImageSrcById(9)}
                                lowResSrc={blur9}
                                alt="picture 9"
                                onClick={() =>
                                    openModal(
                                        9,
                                        galleryData[9]?.title || "9 - Här Kommer Pippi Långstrump"
                                    )
                                }
                            />
                            <LazyImage
                                className="pic11"
                                highResSrc={getImageSrcById(11)}
                                lowResSrc={blur11}
                                alt="picture 11"
                                onClick={() =>
                                    openYouTubeModal(
                                        "LBbfko8H378?si=ICzVnl8e8YUWIi73"
                                    )
                                }
                            />
                            <span></span>
                            <LazyImage
                                className="pic10"
                                highResSrc={getImageSrcById(10)}
                                lowResSrc={blur10}
                                alt="picture 10"
                                onClick={() =>
                                    openModal(
                                        10,
                                        galleryData[10]?.title || "10 - Zoe in Colour & Black and White"
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>

        {modalSrc && (
          <Modal
            srcList={modalSrc}
            onClose={closeModal}
            errorMessage={modalError}
            nimi={modalTitle}
          />
        )}
        <MenuModal
          isOpen={isMenuOpen}
          onClose={closeMenu}
          className={isClosing ? "slide-up" : "slide-down"}
        >
          <div className="menuLinks">
            <h3>Galleries</h3>
            <p
              onClick={() => {
                openModal(1, galleryData[1]?.title || "Menu 1 / Work");
                setIsMenuOpen(false);
              }}
            >
              {galleryData[1]?.title || "Menu - Work"}
            </p>
            <p
              onClick={() => {
                openModal(2, galleryData[2]?.title || "Menu 2 / Jen Littlejohn");
                setIsMenuOpen(false);
              }}
            >
              {galleryData[2]?.title || "Menu - Jen Littlejohn"}
            </p>
            <p
              onClick={() => {
                openModal(3, galleryData[3]?.title || "Menu 3 / Fashion");
                setIsMenuOpen(false);
              }}
            >
              {galleryData[3]?.title || "Menu - Fashion"}
            </p>
            <p
              onClick={() => {
                openModal(4, galleryData[4]?.title || "Menu 4 / Portraits of Friends & (ex)Lovers"); 
                setIsMenuOpen(false);
              }}
            >
              {galleryData[4]?.title || "Menu / Portraits of Friends & (ex)Lovers"}
            </p>
            <p
              onClick={() => {
                openModal(5, galleryData[5]?.title || "Menu 5 / Barbie");
                setIsMenuOpen(false);
              }}
            >
              {galleryData[5]?.title || "Menu - Barbie"}
            </p>
            <p
              onClick={() => {
                openModal(6, galleryData[6]?.title || "Menu 6 / Rodeway Inn");
                setIsMenuOpen(false);
              }}
            >
              {galleryData[6]?.title || "Menu - Rodeway Inn"}
            </p>
            <p
              onClick={() => {
                openModal(7, galleryData[7]?.title || "Menu 7 / Milja");
                setIsMenuOpen(false);
              }}
            >
              {galleryData[7]?.title || "Menu - Milja"}
            </p>
            <p
              onClick={() => {
                openModal(8, galleryData[8]?.title || "Menu 8 / Jung Und Schön");
                setIsMenuOpen(false);
              }}
            >
              {galleryData[8]?.title || "Menu - Jung Und Schön"}
            </p>
            <p
              onClick={() => {
                openModal(9, galleryData[9]?.title || "Menu 9 / Här Kommer Pippi Långstrump");
                setIsMenuOpen(false);
              }}
            >
              {galleryData[9]?.title || "Menu - Här Kommer Pippi Långstrump"}
            </p>
            <p
              onClick={() => {
                if (videoIds.length > 0) {
                  openYouTubeModal(videoIds[0]);
                  setCurrentVideoIndex(0);
                } else {
                  // Handle case where video IDs have not been fetched yet
                  console.log("Video IDs are still loading...");
                }
                setIsMenuOpen(false);
              }}
            >
              Videos
            </p>

            <p
              onClick={() => {
                openModal(10, galleryData[10]?.title || "Menu 10 / Zoe in Colour & Black and White");
                setIsMenuOpen(false);
              }}
            >
              {galleryData[10]?.title || "Menu - Zoe in Colour & Black and White"}
            </p>
            {/*<h4>About Me & Contact Me</h4>*/}
          </div>
        </MenuModal>
        {showYouTubeModal && (
          <YouTubeModal
            videoId={youtubeVideoId}
            onClose={closeYouTubeModal}
            onNext={goToNextVideo}
            onPrev={goToPrevVideo}
            totalVideos={videoIds.length}
            currentIndex={currentVideoIndex}
          />
        )}

        
      </div>
    </div>
  );

}

export default App;
