import React, { useState, useEffect } from "react";
import "../App.css";
import SinglePicModal from "./singlePicModal";

function Modal({ srcList, onClose, errorMessage, nimi }) {
    const [loadedImagesCount, setLoadedImagesCount] = useState(0);
    const [isSinglePicModalOpen, setIsSinglePicModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const columnCount = 1; // Adjust the number of columns as needed

    // Function to split the srcList into columns
    const splitIntoColumns = (srcList, columnCount) => {
        const perColumn = Math.ceil(srcList.length / columnCount);
        const columns = Array.from({ length: columnCount }, () => []);

        for (let i = 0; i < srcList.length; i++) {
            const columnIndex = i % columnCount;
            columns[columnIndex].push(srcList[i]);
        }

        return columns;
    };

    const columns = splitIntoColumns(srcList, columnCount);

    // Handle image load
    const handleImageLoad = () => {
        setLoadedImagesCount((prevCount) => prevCount + 1);
    };

    // Function to open single image modal
    const openSinglePicModal = (imageSrc) => {
        setSelectedImage(imageSrc);
        setIsSinglePicModalOpen(true);
    };

    // Function to close single image modal
    const closeSinglePicModal = () => {
        setIsSinglePicModalOpen(false);
        setSelectedImage(null);
    };

    return (
        <div className="modal-background">
            {!isSinglePicModalOpen && (
                <button className="close-button" onClick={onClose}>
                    X
                </button>
            )}
            <div className="ModaalinNimi">
                <h2>{nimi}</h2>
            </div>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div style={{ display: "flex" }}>
                    {" "}
                    {/* Flex container for columns */}
                    {columns.map((column, columnIndex) => (
                        <div key={columnIndex} className="kuvalista">
                            {column.map((src, rowIndex) => (
                                <img
                                    key={`${columnIndex}-${rowIndex}`}
                                    src={src}
                                    alt={`Kuva ${
                                        columnIndex * column.length + rowIndex
                                    }`}
                                    onLoad={handleImageLoad}
                                    className="imageShow"
                                    style={{
                                        // Control the display of images based on the loadedImagesCount
                                        display:
                                            loadedImagesCount >
                                            columnIndex + rowIndex * columnCount
                                                ? "block"
                                                : "none",
                                        animationDelay: `${
                                            (columnIndex +
                                                rowIndex * columnCount) *
                                            200
                                        }ms`,
                                    }}
                                    onClick={() => openSinglePicModal(src)}
                                />
                            ))}
                        </div>
                    ))}
                </div>
                {errorMessage && (
                    <div className="modal-error-message">{errorMessage}</div>
                )}
                {isSinglePicModalOpen && (
                    <SinglePicModal
                        PicId={selectedImage}
                        onClose={closeSinglePicModal}
                    />
                )}
            </div>
        </div>
    );
}

export default Modal;
