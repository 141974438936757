import React from "react";
import "../App.css";

const singlePicModal = ({ PicId, onClose }) => {
    return (
        <div className="menu-modal2" onClick={onClose}>
            <div className="kuvaContainer">
                <img src={PicId} className="imageShow" />
            </div>

            <button className="imageClose-button" onClick={onClose}>
                X
            </button>
        </div>
    );
};

export default singlePicModal;
